(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbLoggedUser.service:LoggedUser
   *
   * @description Applicationwide service to store the authenticated user and it's selected company
   * @description Applicationwide service to store the authenticated user and it's selected company
   */
  angular
    .module('wbLoggedUser')
    .service('LoggedUser', LoggedUser);

  function LoggedUser(User, $rootScope, Company, $cookies, _, OrderService) {
    var user = null
      , company = null
      , loggedUserCompanyIdCookieKey = 'loggedUserCompanyId';

    /**
     * Check user authentication status
     *
     * @returns {boolean} based on service variable value
     */
    this.isAuthenticated = function isAuthenticated() {
      return !_.isNull(user);
    };

    /**
     * Set the user in a service variable
     *
     * @param {integer} userId Id of the user
     * @returns {*|Function} The promise of the user call
     */
    this.setUserById = function setUserById(userId) {
      var userPromise
        , userTemp;

      userTemp = User.get({id: userId});
      userPromise = userTemp.$promise;

      userPromise.then(function () {
        user = userTemp;
        $rootScope.$broadcast('user.login');
      });

      return userPromise;
    };

    /**
     * To pass the current user for caller functions
     *
     * @returns {Object|null} current user or null
     */
    this.getUser = function getUser() {
      return user;
    };

    /**
     * Clear out the authenticated user from the application
     */
    this.empty = function empty() {
      user = null;
      company = null;
      $cookies.remove(loggedUserCompanyIdCookieKey);
      $rootScope.$broadcast('user.logout');
    };

    /**
     * Set the selected company in a service variable
     *
     * @param {integer} companyId Id of the company
     * @returns {*|Function} The promise of the company call
     */
    this.setCompany = function setCompany(companyId) {
      company = Company.get({companyId: companyId});
      $cookies.put(loggedUserCompanyIdCookieKey, companyId);

      return company.$promise.then(OrderService.updateCart);
    };

    /**
     * To pass the current company for caller functions
     *
     * @returns {Object|null} current company or null
     */
    this.getCompany = function getCompany() {
      return company;
    };
  }
}());
